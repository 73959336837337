import React from 'react'
import './ErrorModalMessage.css'

const ErrorModalMessage = ({errorMessage, descriptionText, onButtonClick }) => {
  return (

    <div className={'errorMessageModalOverlay'}>
    <div className={'errorMessageErrorStyle'}>
    <h1>Error {errorMessage}</h1>
    <br/>
    {descriptionText}
    <br />
    <br />
    Om felet kvarstår, kontakta vår support på telefon
    <br />
    070 793 74 43
    <br />
    <br />
    <button className={'errorMessageButtonAccept'} onClick={onButtonClick}>
      Uppfattat
    </button>
    </div>
  </div>

  )
}

export default ErrorModalMessage