import { React, useEffect, useState } from 'react'
import './BaseDropdownInput.css'

const BaseDropdownInput = ({ dropdownInfo, placeholder, userInput, setUserInput }) => {
  const [selected, setSelected] = useState('')

  useEffect(() => {
    setSelected(userInput)
  }, [userInput])
  
  
  const handleChange = (event) => {
    setSelected(event.target.value) 
    setUserInput(event.target.value)
  }

  return (
    <div className="inputRow">
      <div>
        <select
          value={selected}
          className="dropdownInput"
          onChange={handleChange}
        >
          <option key={999} value={""}>-</option>
          {dropdownInfo?.map((info, index) => (
            <option key={index} value={info}>{info}</option>
          ))}
        </select>
      </div>
      <span className="dropdownInput__label">{placeholder}</span>
    </div>
  )
}

export default BaseDropdownInput
