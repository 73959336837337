import React, { useEffect, useRef } from 'react'
import AddSearchPhraseButton from '../../atoms/buttons/addSearchPhraseButton/AddSearchPhraseButton'
import SearchPhraseButton from '../../atoms/buttons/searchPhraseButton/SearchPhraseButton'
import SearchInput from '../../atoms/inputs/searchInput/SearchInput'
import './MultiWordInput.css'


const MultiWordInput = ({
  handleRequest,
  latestSearch,
  setLatestSearch,
  setDisableSearch,
  searchParam, setSearchparam,
  enterPressed, setEnterPressed,
 
}) => {

  const inputRef = useRef(null)
  const focusToInputRef = useRef()
  
  useEffect(() => {
    if (latestSearch.searchParams?.length < maxNumberOfSearchParams) {
      addSearchWord()
      setEnterPressed(true)
      handleRequest()
    }
    if (latestSearch.searchParams?.length === maxNumberOfSearchParams) {
      handleRequest()
    }

    setEnterPressed(false)
  }, [enterPressed])
  useEffect(
    ()=>{
      setDisableSearch((latestSearch.searchParams.length === 0 && !searchParam) ? true : false)
    }
  ,[searchParam,latestSearch,setDisableSearch])

  const focusIn = () => {
    inputRef.current.style.boxShadow = "0 0 0 2px #6389eb";
  }

    const focusOut = () => {
    inputRef.current.style.boxShadow = "0 0 0 0 #fff"
  }

  const setFocusToInputField = () => {
    focusToInputRef.current.focus()
  }

  let maxNumberOfSearchParams = 3

  const inputHandler = (event) => {
    setSearchparam(event.target.value)
  }

  const addSearchWord = () => {
    if (
      searchParam !== '' &&
      latestSearch.searchParams?.length < maxNumberOfSearchParams
    ) {
      setLatestSearch({searchParams: [...latestSearch.searchParams, searchParam]})
      setSearchparam('')
    }
  }
  const handleKeyPress = (e) => {

    if (e.key === 'Enter') {
      setEnterPressed(true)
    }
    if (e.key === 'Escape') {
      let inputIndex = latestSearch.searchParams?.length - 1
      setLatestSearch({searchParams: [
        ...latestSearch.searchParams.slice(0, inputIndex),
        ...latestSearch.searchParams.slice(inputIndex + 1, latestSearch.searchParams.length),
      ]})
    }
    if (e.key === 'Backspace') {
      if (searchParam === '') {
        let inputIndex = latestSearch.searchParams?.length - 1
        setLatestSearch({searchParams: [
          ...latestSearch.searchParams.slice(0, inputIndex),
          ...latestSearch.searchParams.slice(inputIndex + 1, latestSearch.searchParams.length),
        ]})
      }
    }
  }

  const handleSearchBtnClick = (e) => {

      if (latestSearch.searchParams?.length < maxNumberOfSearchParams) {
        addSearchWord()
        handleRequest()
      }
      if (latestSearch.searchParams?.length === maxNumberOfSearchParams) {
        handleRequest()
      }
    
  }

  const removeSearchWord = (input) => {
    let inputIndex = latestSearch.searchParams.indexOf(input)
    setLatestSearch({searchParams: [
      ...latestSearch.searchParams.slice(0, inputIndex),
      ...latestSearch.searchParams.slice(inputIndex + 1, latestSearch.searchParams.length),
    ]})
  }
  document.onkeydown = handleKeyPress

  //document?.getElementById('searchButton')?.addEventListener("click", handleSearchBtnClick);
  

  return (
    <div className={'multiWord'}  >
      <div className={'multiWord__InputField'} ref={inputRef} onClick={setFocusToInputField}>
      {latestSearch.searchParams?.map((word, index) => {
            return (
              <SearchPhraseButton
                key={index}
                removeSearchWord={removeSearchWord}
                searchWord={word}
              />
              )
            })}
        <SearchInput
          searchInputHandler={inputHandler}
          searchInputValue={searchParam}
          focusIn={focusIn}
          focusOut={focusOut}
          focusToInputRef={focusToInputRef}
          latestSearch={latestSearch}

          maxNumberOfSearchParams={latestSearch.searchParams.length === maxNumberOfSearchParams}
        />
        <AddSearchPhraseButton
          addSearchPhraseButtonOnClickFunction={addSearchWord}
          latestSearch={latestSearch}
          setLatestSearch={setLatestSearch}
        />
      </div>

    </div>
  )
}

export default MultiWordInput
