import React from 'react'
import PaginationButton from '../../../atoms/buttons/paginationButton/PaginationButton'

const PaginationButtons = ({selectedValue, clickLink, totalNumberOfPages, tablePage }) => {
    
      const selectedPageMarker = (number) => {
        if(tablePage){
          selectedValue = tablePage
        }

        if (number === selectedValue) {
          return (
            <PaginationButton isSelected={'activePageIndicator'} clickLink={clickLink} buttonValue={number}/> 
          )
        } 
        else {
          return (
            <PaginationButton clickLink={clickLink} buttonValue={number}/> 
          )
        }        
      }      

    function createAllButtons() {

        var numberOfPages = []
        var showSelectedLow = (selectedValue - 2)
        var showSelectedHigh = (selectedValue + 2)

        if (selectedValue == 1) {
          showSelectedLow = selectedValue
          showSelectedHigh = selectedValue + 4
        }
        if (selectedValue == 2) {
          showSelectedLow = selectedValue - 1
          showSelectedHigh = selectedValue + 3
        }
        if (totalNumberOfPages - selectedValue == 0) {
          showSelectedLow = selectedValue - 4
          showSelectedHigh = selectedValue
        }
        if (totalNumberOfPages - selectedValue == 1) {
          showSelectedLow = selectedValue - 3
          showSelectedHigh = selectedValue + 1
        }
        for (
          let index = showSelectedLow - 2;
          index < totalNumberOfPages + 2;
          index++
        ) {
          if (index >= showSelectedLow && index <= showSelectedHigh && index > 0 && index <= totalNumberOfPages)
            numberOfPages.push(index)
        }
        return (
          <div className={`${'pagination'} ${'pagination_centered'}`}>
            <ul>
              <li>
            <PaginationButton clickLink={clickLink} buttonValue={'Första'}/> 
              </li>
              {numberOfPages.map((number, index) => (
                  <li key={index}>
                  {selectedPageMarker(number)}
                  </li>
              ))}
              <li>
              <PaginationButton clickLink={clickLink} buttonValue={'Sista'}/> 
              </li>
            </ul>
          </div>
        )
       }
  return (
    <div>
    {createAllButtons()}
    <br />
  </div>
  )
}

export default PaginationButtons