import React from 'react'

const BaseButton = ({buttonText, buttonOnClickFunction, buttonClassName, activeOrDisabled}) => {

  const BaseButtonOnClick = () => {
    buttonOnClickFunction()
  }

  return (
    <button className={buttonClassName} onClick={BaseButtonOnClick} disabled={activeOrDisabled} >{buttonText}</button>
  )
}

export default BaseButton