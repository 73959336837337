import './App.css'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ArchiveSearchPage } from './components/page/archiveSearchPage/ArchiveSearchPage'
import { HistorySearchPage } from './components/page/historySearchPage/HistorySearchPage'
import { VolumeSearchPage } from './components/page/volumeSearchPage/VolumeSearchPage'
import NavigationBar from './components/molecules/navigationBar/NavigationBar'
import FreeSearchPage from './components/page/freeSearchPage/FreeSearchPage'

const App = () => {
  
  return (
    <div className="App">
      <NavigationBar
      />
      <Routes>
        <Route
          path="/"
          element={
            <FreeSearchPage
            />
          }
        />
        <Route
          path="/detailed/archiveholder"
          element={
            <ArchiveSearchPage
            />
          }
        />
         <Route
           path="/detailed/history"
           element={
             <HistorySearchPage
             />
           }
        /> 
        <Route
          path="/detailed/volumes"
          element={
            <VolumeSearchPage
            />
          }
        />
      </Routes>
    </div>
  )
}

export default App
