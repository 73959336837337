import { React, useEffect, useState } from 'react'
import '../baseDropdownInput/BaseDropdownInput.css'

const NadDropdownInput = ({ nadArray, placeholder, userInput, setUserInput }) => {
  const [selected, setSelected] = useState('')

  useEffect(() => {
    setSelected(userInput)
  }, [userInput])
  
  
  const handleChange = (event) => {
    setSelected(event.target.value) 
    setUserInput(event.target.value)
  }

  return (

    <div className="inputRow">
      <div>
        <select
          value={selected}
          className="dropdownInput"
          onChange={handleChange}
        >
          <option key={999} value={""}>-</option>
          {nadArray?.map((info, index) => (
            <option key={index} value={info.value}>{info.name}</option>
          ))}
        </select>
      </div>
      <span className="dropdownInput__label">{placeholder}</span>
    </div>
  )
}

export default NadDropdownInput
