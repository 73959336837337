import React from 'react'
import Icon from '../../icon/Icon'
import './ClearButton.css'

const ClearButton = ({ clearMethod }) => {

  const buttonText = 'Rensa Allt'
  const xMark = <Icon name="close" />



  return (
    <div className='clearButton' onClick={clearMethod}>
    {xMark} {buttonText}
    </div>
  )
}

export default ClearButton
