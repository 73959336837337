import React from 'react'
import InformationModalButton from '../../atoms/buttons/InformationModalButton/InformationModalButton'
import Icon from '../../atoms/icon/Icon'
import './InformationModal.css'

const InformationModal = ({informationModalToggler}) => {
  const InformationModalButtonCloseModalText = 'Stäng'

  const questionMark = <Icon name="help" />
  

  return (
    <div className={'informationModalOverlay'}>
      <div className={'informationModalDisplayStyles'}>
        <div className={'informationModalH1'}> 
          {questionMark}  
          <div className='informationModalTitle'>Information om fritextsök</div>
          {/* <InformationModalCloseWithXIcon InformationModalCloseWithXIconOnClickFunction={informationModalToggler} />  */}
        </div>
          
        <br/>
        <div className='informationModalText'>
        <p>1. Du kan söka med ett till tre ord.</p>
        <p>2. Klicka på plussknappen eller "Enter" på tangentbordet för att lägga till ett sökord. </p>
        <p>3. Klicka på krysset vid ett sökord för att ta bort det eller använd backsteg på tangentbordet. </p>
        <p>4. Frisökningen kommer leta efter sökresultat som innehåller sökorden.</p>
        <p>5. För mer information klicka på 'Hjälp'-fliken.</p>
        </div>
        
          

      <div className={'informationModalButtonPosition'} >
      <InformationModalButton InformationModalButtonText={InformationModalButtonCloseModalText} InformationModalButtonOnclick={informationModalToggler} />
      </div>
    </div>
  </div>  
  )
}

export default InformationModal