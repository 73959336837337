import { React, useState, useEffect } from 'react'
import SearchArchiveholders from '../../molecules/searchArchiveholders/SearchArchiveholders'
import { detailedSearchRequest}  from '../../../helpers/api/SearchRequest'
import './ArchiveSearchForm.css'
import DetailedFormSearchButton from '../../atoms/buttons/detailedFormSearchButton/DetailedFormSearchButton'
import {
  setLatestSearchLocalStorage,
} from '../../../helpers/localStorage/LocalStorage'


const ArchiveSearchForm = ({ latestSearch, setLatestSearch, dropdownInfo, setIsOpen, setRequest, setIsLoading, setHasSearched, clearSearchResults, setStoredArchiveResult }) => {
  const path = window.location.href;
  const [status, setStatus] = useState()
  const [userInput, setUserInput] = useState({Depot: "", Archive_ArchiveHolderName: "", ArchiveNote: "", PlacementNote: "", NADKat: undefined, OperationType: "", OperationPlace: "", Page: 1})
  const [disableSearch, setDisableSearch] = useState(true)
  const apiSearchPath = '/api/search/DetailedArchiveSearch'

  const handleRequest = () => {
    detailedSearchRequest(path, apiSearchPath, setLatestSearch, setIsOpen, setRequest, setIsLoading, setHasSearched, setStoredArchiveResult, setUserInput, userInput, setStatus, latestSearch)
  }

  useEffect(() => {
    if(latestSearch?.latestSearchData !== null){
      setLatestSearchLocalStorage(latestSearch)
    }
  }, [latestSearch])
 
  return (
    <div className="detailedForm">
      <SearchArchiveholders
        userInput={userInput}
        setUserInput={setUserInput}
        dropdownInfo={dropdownInfo}
        latestUserInput={latestSearch.searchParams} 
        clearSearchResults={clearSearchResults}
        setDisableSearch={setDisableSearch}
        />
      <div className="detailedForm__buttonContainer">
      <DetailedFormSearchButton SearchButtonOnClick={handleRequest} activeOrDisabled={disableSearch} />
      </div>
    </div>
  )
}

export default ArchiveSearchForm
