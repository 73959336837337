import React, { useEffect, useRef, useState } from 'react'
import { freeSearchPaginationHandler } from '../../../helpers/api/PaginationRequest'
import { freeSearchRequest } from '../../../helpers/api/SearchRequest'
import {
  getLatestSearchLocalStorage,
  setLatestSearchLocalStorage
} from '../../../helpers/localStorage/LocalStorage'
import Icon from '../../atoms/icon/Icon'
import LoadingSpinner from '../../atoms/spinners/loadingSpinner/LoadingSpinner'
import ErrorMessageModal from '../../molecules/errorMessageModal/ErrorMessageModal'
import SearchStatus from '../../molecules/searchStatus/SearchStatus'
import FreeSearchForm from '../../organisms/freeSearchForm/FreeSearchForm'
import SearchResultTable from '../../organisms/searchResultTable/SearchResultTable'
import SearchTablePagination from '../../organisms/searchTablePagination/SearchTablePagination'
import './FreeSearchPage.css'

const FreeSearchPage = () => {
  const path = window.location.href
  const abortController = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hasSearched, setHasSearched] = useState(false)
  const [status, setStatus] = useState()
  const [storedFreeResult, setStoredFreeResult] = useState({})

  const [latestSearch, setLatestSearch] = useState({
    latestSearchData: null,
    searchParams: [],
    numberOfSearchResult: 0,
    currentTablePage: 1,
    returnPath: '',
  })

  const nameOfComponent = "freeSearch"
  const typeOfStorage = 'freeSearchStorage'

  
  
  useEffect(() => {
    var latestSearch = getLatestSearchLocalStorage(path)
    if (latestSearch) {
      setLatestSearch(latestSearch)
      setHasSearched(true);
      setStoredFreeResult(JSON.parse(localStorage.getItem("freeSearchStorage")))
    }
  }, [])
  
  useEffect(() => {
    if(storedFreeResult.length !== 0){
      localStorage.setItem("freeSearchStorage", JSON.stringify(storedFreeResult));
      console.log(storedFreeResult)
    }
  }, [storedFreeResult])
  
  useEffect(() => {
    if(latestSearch?.latestSearchData){
      setLatestSearchLocalStorage(latestSearch)
    }
  }, [latestSearch])


  const requestHandler = (searchParameters) => {
    freeSearchRequest(searchParameters, setIsLoading, abortController, setHasSearched, setStatus, setStoredFreeResult, setLatestSearch, latestSearch, path, typeOfStorage)
}

const paginationHandler = (selectedPageNumber) => {
  freeSearchPaginationHandler(selectedPageNumber, setIsLoading, setLatestSearch, latestSearch, path, setHasSearched, setStatus, setStoredFreeResult, typeOfStorage)
}


  const clearStatusCode = () => {
    setStatus()
  }


  const cancelSearch = () => {
    abortController.current && abortController.current.abort()
    setIsLoading(false)
    setHasSearched(false)
    setStatus()
  }

  return (
    <div className="freeSearchPage">
      {status && !isLoading && (
        <ErrorMessageModal
          statusCode={status}
          clearStatusCode={clearStatusCode}
        />
      )}
      <FreeSearchForm
        latestSearch={latestSearch}
        setLatestSearch={setLatestSearch}
        requestHandler={requestHandler}
        isLoading={isLoading}
        cancelSearch={cancelSearch}
        hasSearched={hasSearched}
      />
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <SearchStatus
        hasSearched={latestSearch.numberOfSearchResult > 0 ? true : hasSearched}
        parentPage={nameOfComponent}
        latestSearch={latestSearch}
        />
        )}
        {!isLoading &&
          latestSearch.numberOfSearchResult === 0 && (
            
            <Icon name="search" className="freeSearchPage__magnifyingGlass" />
          )}
      {latestSearch.numberOfSearchResult > 0  &&
        !isLoading && (
          <div>
             <SearchResultTable
              latestSearch={latestSearch}
            />
            <SearchTablePagination
              requestHandlerPagination={paginationHandler}
              numberOfResults={latestSearch.numberOfSearchResult}
              tablePage={latestSearch.currentTablePage}
            />
          </div>
        )}
    </div>
  )
}

export default FreeSearchPage
