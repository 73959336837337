import { React, useEffect } from 'react'
import './AdvancedSearchInput.css'

const AdvancedSearchInput = ({ placeholder, inputToUpdate, setUserInput, userInput }) => {

 
  const handleChange = (event) => {
    setUserInput(event.target.value)
    
  }

  return (
    <div className='inputRow'>
    <input type="text" className='inputRow__advancedSearchInput' onChange={handleChange} value={userInput}></input>
    <span className='inputRow__label'>{placeholder}</span>
    </div>
  )
}

export default AdvancedSearchInput