import Fetcher from '../fetcher/Fetcher'

const RequestInputData = async (apiInputPath, setIsLoading, setDropdownInfo, setStatus) => {

    setIsLoading(true)
    const requestBody = {}
    const selectURL =
      process.env.REACT_APP_API_URL + apiInputPath
    const responsedata = await Fetcher('POST', requestBody, selectURL)
    setStatus(responsedata.status)
    const data = await responsedata.json()
    if (data) {

      setTimeout(function () {
        setIsLoading(false)
      }, 0)
      setDropdownInfo(data)
    }
  }

  export default RequestInputData