import React from 'react'
import Icon from '../../icon/Icon'
import './FreeTextFormSearchButton.css'

const SearchButton = ({SearchButtonOnClick, activeOrDisabled}) => {

 const buttonText = "Sök"
 
  return (
    <button className='searchButton' onClick={SearchButtonOnClick} disabled={activeOrDisabled} ><Icon name="search"/>{buttonText}</button>
  )
}

export default SearchButton