import Fetcher from '../fetcher/Fetcher'

const pageSorter = (selectedPageNumber, latestSearch, data, typeOfStorage) => {
  var objectWithArray = []
  let pageCounter = 0;
  let storedResults = JSON.parse(localStorage.getItem(typeOfStorage))
  for (let i = (selectedPageNumber - 1) * 20; i < latestSearch.numberOfSearchResult; i += 20) {
    let pageObject = {
      page: (i + 20) / 20,
      data: data.SearchResults.slice(pageCounter, pageCounter + 20)
    }
    pageCounter++;
    if (storedResults && !storedResults.find(x => x.page === pageObject.page)) {
      objectWithArray.push(pageObject)
    }
  }
  return objectWithArray
}


export const detailedPaginationRequest = async (path, setIsLoading, apiPath, typeOfStorage, selectedPageNumber, setLatestSearch, latestSearch, request, setStoredResult) => {
  let storedResults = JSON.parse(localStorage.getItem(typeOfStorage))
  if (storedResults.find(x => x.page === selectedPageNumber)) {
    let selectedResults = storedResults.find(x => x.page === selectedPageNumber).data
    setLatestSearch({ ...latestSearch, currentTablePage: selectedPageNumber, latestSearchData: selectedResults, returnPath: path })
  }
  else {
    setIsLoading(true)
    let requestBody = { ...request, Page: selectedPageNumber }
    const responsedata = await Fetcher(
      'POST',
      requestBody,
      process.env.REACT_APP_API_URL + apiPath
    )
    const data = await responsedata.json()
    if (data) {

      var objectWithArray = pageSorter(selectedPageNumber, latestSearch, data, typeOfStorage)

      setStoredResult((storedResultsState) => [...storedResultsState, ...objectWithArray]);
      let storedResults = JSON.parse(localStorage.getItem(typeOfStorage))
      let tempStored = [...storedResults, ...objectWithArray]
      let selectedResults = tempStored.find(x => x.page === selectedPageNumber).data
      setLatestSearch({ ...latestSearch, currentTablePage: selectedPageNumber, latestSearchData: selectedResults, numberOfSearchResult: data.TotalCount, returnPath: path })
    }
  }
  setTimeout(function () {
    setIsLoading(false)
  }, 0)
}

export const freeSearchPaginationHandler = async (selectedPageNumber, setIsLoading, setLatestSearch, latestSearch, path, setHasSearched, setStatus, setStoredFreeResult, typeOfStorage) => {
  let storedResults = JSON.parse(localStorage.getItem(typeOfStorage))
  if (storedResults.find(x => x.page === selectedPageNumber)) {
    let selectedResults = storedResults.find(x => x.page === selectedPageNumber).data
    setLatestSearch({ ...latestSearch, currentTablePage: selectedPageNumber, latestSearchData: selectedResults, returnPath: path })
  }
  else {
    setIsLoading(true)
    setHasSearched(true)
      var requestBody = {
        searchStrings: latestSearch.searchParams,
        page: selectedPageNumber,
      }

    const responsedata = await Fetcher(
      'POST',
      requestBody,
      process.env.REACT_APP_API_URL + '/api/search/SearchAll'
    )
    setStatus(responsedata.status)
    const data = await responsedata.json()
    if (data) {

      var objectWithArray = pageSorter(selectedPageNumber, latestSearch, data, typeOfStorage)

      setStoredFreeResult((storedResultsState) => [...storedResultsState, ...objectWithArray]);
      let storedResults = JSON.parse(localStorage.getItem(typeOfStorage))
      let tempStored = [...storedResults, ...objectWithArray]
      let selectedResults = tempStored.find(x => x.page === selectedPageNumber).data
      setLatestSearch({ ...latestSearch, currentTablePage: selectedPageNumber, latestSearchData: selectedResults, numberOfSearchResult: data.TotalCount, returnPath: path })
    }
  }
  setTimeout(function () {
    setIsLoading(false)
  }, 0)
}



