import React from 'react'
import './HeaderButtonTitle.css'

const HeaderButtonTitle = ({buttonText}) => {

  return (
    <p className='headerButtonTitle'>{buttonText}</p>
  )
}

export default HeaderButtonTitle