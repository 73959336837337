import { React, useState, useEffect } from 'react'
import './VolumeSearchForm.css'
import SearchVolumes from '../../molecules/searchVolumes/SearchVolumes'
import DetailedFormSearchButton from '../../atoms/buttons/detailedFormSearchButton/DetailedFormSearchButton'
import {setLatestSearchLocalStorage} from '../../../helpers/localStorage/LocalStorage'
import {detailedSearchRequest} from '../../../helpers/api/SearchRequest'

const VolumeSearchForm = ({ latestSearch, setLatestSearch, dropdownInfo, setIsOpen, setRequest, setIsLoading, setHasSearched, clearSearchResults, setStoredVolumeResult }) => {
  const path = window.location.href;
  const [status, setStatus] = useState()
  const [userInput, setUserInput] = useState({
      archiveHolderName: "", serieSignumOrHandlingsslagStructure: "", seriesNameOrHandlingsslagName: "", note: "", 
      handlingsTyp: "", volumeNumberOrStorageUnitBeteckning: "", scope: "",
      storageUnitType: "", placement : "", noteSearchParameters: [], Page: 1})     
  const [disableSearch, setDisableSearch] = useState(true)
    const apiSearchPath = '/api/search/DetailedVolumeStorageUnitSearch'

    const handleRequest = () => {
      detailedSearchRequest(path, apiSearchPath, setLatestSearch, setIsOpen, setRequest, setIsLoading, setHasSearched, setStoredVolumeResult, setUserInput, userInput, setStatus, latestSearch)
  }

  useEffect(() => {
    if(latestSearch?.latestSearchData !== null){
      setLatestSearchLocalStorage(latestSearch)
    }
  }, [latestSearch])

  return (
    <div className="volumeSearchForm">
      <SearchVolumes 
        userInput={userInput}
        setUserInput={setUserInput}
        dropdownInfo={dropdownInfo}
        latestUserInput={latestSearch.searchParams} 
        clearSearchResults={clearSearchResults} 
        setDisableSearch={setDisableSearch}/>
      <div className="volumeSearchForm__buttonContainer">
      <DetailedFormSearchButton SearchButtonOnClick={handleRequest} activeOrDisabled={disableSearch} />
      </div>
    </div>
  )
}

export default VolumeSearchForm
