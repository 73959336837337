import { React, useEffect } from 'react'
import TableHead from '../../atoms/table/tableHead/TableHead'
import TableRow from '../../atoms/table/tableRow/TableRow'

import './SearchResultTable.css'

const SearchResultTable = ({
  latestSearch
}) => {

  return (
    <table className={`${'table'}`}>
      <TableHead />
      <tbody>
        {latestSearch.latestSearchData?.map((data, index) => (
            <TableRow
              key={index}
              result={data}
              latestSearch={latestSearch}
              searchResults={latestSearch.latestSearchData}
              latestUserInput={latestSearch.searchParams}
              tablePage={latestSearch.currentTablePage}
              numberOfResults={latestSearch.numberOfSearchResult}
              searchParams={latestSearch.searchParams}
              storedSearchParams={latestSearch.searchParams}
            />
        ))}
      </tbody>
    </table>
  )
}

export default SearchResultTable
