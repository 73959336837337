import React, { useState } from 'react'
import AdvancedSearchButton from '../../atoms/buttons/advancedSearchButton/AdvancedSearchButton'
import FreeTextSearchButton from '../../atoms/buttons/freeTextSearchButton/freeTextSearchButton'
import './navigationBar.css'

const NavigationBar = () =>{
    const [freeActive, setFreeActive] = useState(true);
    const [active, setActive] = useState(false);


    const ClickHandler = (button) => {
        if(button == "free"){
            setFreeActive(true)
            setActive(false)
        }
        else{
            setFreeActive(false)
            setActive(true)
        }


    }

    return(
        <div className="navigationBar">
            <FreeTextSearchButton active={freeActive} setActive={setActive} clickHandler={ClickHandler}/>
            <AdvancedSearchButton active={active} clickHandler={ClickHandler}/>
        </div>
    ) 
}


export default NavigationBar