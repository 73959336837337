import { React, useEffect, useState } from 'react'
import BaseButton from '../../atoms/buttons/baseButton/BaseButton'
import BaseDropdownInput from '../../atoms/inputs/baseDropdownInput/BaseDropdownInput'
import AdvancedSearchInput from '../../atoms/inputs/advancedSearchInput/AdvancedSearchInput'
import H3Title from '../../atoms/titles/h3Title/H3Title'
import './SearchArchiveholders.css'

import * as NAD from '../../../data/NADCategories'
import NadDropdownInput from '../../atoms/inputs/nadDropdownInput/NadDropdownInput'
import ClearButton from '../../atoms/buttons/clearButton/ClearButton'
import * as utils from '../../../helpers/utils'

const SearchArchiveholders = ({ dropdownInfo, userInput, setUserInput, latestUserInput, clearSearchResults, setDisableSearch }) => {
  
  const [archiveName, setArchiveName] = useState(latestUserInput.Archive_ArchiveHolderName ? latestUserInput.Archive_ArchiveHolderName : '')
  const [archiveNote, setArchiveNote] = useState(latestUserInput.ArchiveNote ? latestUserInput.ArchiveNote : '')
  const [placementNote, setPlacementNote] = useState(latestUserInput.PlacementNote ? latestUserInput.PlacementNote : '')
  const [operationPlace, setOperationPlace] = useState(latestUserInput.OperationPlace ? latestUserInput.OperationPlace : '')
  const [nadKat, setNadKat] = useState(latestUserInput.NADKat ? latestUserInput.NADKat : '')
  const [operationTypes, setOperationType] = useState(latestUserInput.OperationType ? latestUserInput.OperationType : '')
  const [depot, setDepot] = useState(latestUserInput.Depot ? latestUserInput.Depot : '')

  const nad = 'NAD-Kategori'
  const ope = 'Verksamhetstyp'

  const upperTitle = 'Depå'
  const lowerTitle = 'Arkivbildare'

  const archiveInputPlaceholder = 'Arkiv-/Arkivbildarnamn'
  const archiveNotePlaceholder = 'Interna anteckningar'
  const archivePlacement = 'Placering'
  const locality = 'Verksamhetsort'
  const depotDropdownPlaceholder = 'Depå'

  useEffect(()=>{
    setDisableSearch((utils.isEmptyUserInput(userInput) ? true : false ))
  }, [userInput, setDisableSearch])

  useEffect(() => {   
    setUserInput({...userInput, Depot: depot, Archive_ArchiveHolderName: archiveName, ArchiveNote: archiveNote, PlacementNote: placementNote, OperationPlace: operationPlace, NADKat: nadKat, OperationType: operationTypes})
  }, [archiveName, archiveNote, placementNote, operationPlace, nadKat, operationTypes, depot])

  const clearInputFields = () => {
    setArchiveName('')
    setArchiveNote('')
    setPlacementNote('')
    setOperationPlace('')
    setNadKat('')
    setOperationType('')
    setDepot('')
    clearSearchResults()
  }
  
  return (
    <div className="upperInputFields">
      <H3Title h3TitleText={upperTitle} />
      <div className="depotInputField">
      <BaseDropdownInput dropdownInfo={dropdownInfo.depots} placeholder={depotDropdownPlaceholder} userInput={depot} setUserInput={setDepot}/>
      </div>
      <H3Title h3TitleText={lowerTitle} />
      <AdvancedSearchInput placeholder={archiveInputPlaceholder} userInput={archiveName} setUserInput={setArchiveName} />
      <AdvancedSearchInput placeholder={archiveNotePlaceholder} userInput={archiveNote} setUserInput={setArchiveNote}/>
      <AdvancedSearchInput placeholder={archivePlacement} userInput={placementNote} setUserInput={setPlacementNote}/>
      <AdvancedSearchInput placeholder={locality} userInput={operationPlace} setUserInput={setOperationPlace}/>
      <NadDropdownInput nadArray={NAD.nadCategories} placeholder={nad} userInput={nadKat} setUserInput={setNadKat} ></NadDropdownInput>
      <BaseDropdownInput dropdownInfo={dropdownInfo.operationTypes} placeholder={ope} userInput={operationTypes} setUserInput={setOperationType} />
      <div className='archiveArchiveHolderClearButton'>
      <ClearButton clearMethod={clearInputFields}/>
      </div>
    </div>
  )
}

export default SearchArchiveholders
