import React from 'react'
import Icon from '../../icon/Icon'
import './ToggleInformationModalButton.css'

const ToggleInformationModalButton = ({toggleInformationModalButtonOnClickFunction}) => {
  
  const questionMark = <Icon name="help" />
  
  const toggleInformationModalButtonText = 'Hjälp'

  return (
    <div className='informationButton' onClick={toggleInformationModalButtonOnClickFunction}>
     <p className='informationButton__Content'> {questionMark} {toggleInformationModalButtonText} </p>
    </div>
    )
}

export default ToggleInformationModalButton