
export const setLatestSearchLocalStorage = (latestSearch) => {
    const now = new Date();
    const durationToStoreSearch = 24 * 60 * 60 * 1000;
    let toStore = {}
    toStore.latestSearchData = JSON.stringify(latestSearch.latestSearchData);
    toStore.numberOfSearchResult = latestSearch.numberOfSearchResult.toString();
    toStore.currentTablePage = latestSearch.currentTablePage;
    toStore.returnPath = latestSearch.returnPath;
    toStore.expiry = now.getTime() + durationToStoreSearch;

    if(Array.isArray(latestSearch.searchParams)){
        toStore.searchParams = latestSearch.searchParams.toString();
    }
    else{
        toStore.searchParams = JSON.stringify(latestSearch.searchParams)
    }
    toStore.searchParams = latestSearch.searchParams;

    var data = JSON.stringify(toStore);
    localStorage.setItem(toStore.returnPath, data);
}

export const getLatestSearchLocalStorage = (returnPath) => {
    var storedSearch = localStorage.getItem(returnPath);

    if(storedSearch){
      var data = JSON.parse(storedSearch);
      const now = new Date();

      if (data.expiry && data.expiry > now.getTime()) { 
          const latestSearch = {
            latestSearchData: JSON.parse(data.latestSearchData),
            searchParams: data.searchParams,
            numberOfSearchResult: parseInt(data.numberOfSearchResult),
            currentTablePage: parseInt(data.currentTablePage),
            returnPath: data.returnPath
          }
          return latestSearch;
      } else {
        localStorage.removeItem(returnPath);
        return null;
    }

    }
}

export const removeLatestSearchLocalStorage = (returnPath) => {
    localStorage.removeItem(returnPath);
}

