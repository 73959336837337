import React from 'react'
import HeaderButtonSign from '../../atoms/headerSign/HeaderSign'
import HeaderButtonTitle from '../../atoms/titles/headerButtonTitle/HeaderButtonTitle'
import HeaderButtonText from '../../atoms/titles/headerButtonText/HeaderButtonText'
import './HeaderButton.css'

const HeaderButton = ({buttonText, isOpen, setIsOpen}) => {

  const handleOnClick = () => {
    if (isOpen === true) {
      setIsOpen(false)
    }
    else { 
      setIsOpen(true)
    }
  }


  return (
    <button className='headerButton' onClick={ () => handleOnClick()}>
    <div className='headerButton__Container'>
    <HeaderButtonTitle buttonText={buttonText}/>
    <div className='headerButton__TextContainer'>
    <HeaderButtonText isOpen={isOpen}/>
    <HeaderButtonSign isOpen={isOpen} setIsOpen={setIsOpen}  />
    </div>
    </div>
    </button>
  )
}

export default HeaderButton