import { React, useState, useEffect } from 'react'
import HeaderButton from '../../molecules/headerButton/HeaderButton'
import DetailedResultTable from '../../organisms/detailedResultTable/DetailedResultTable'
import VolumeSearchForm from '../../organisms/volumeSearchForm/VolumeSearchForm'
import SearchTablePagination from '../../organisms/searchTablePagination/SearchTablePagination'
import SearchStatus from '../../molecules/searchStatus/SearchStatus'
import './VolumeSearchPage.css'
import LoadingSpinner from '../../atoms/spinners/loadingSpinner/LoadingSpinner'
import {detailedPaginationRequest} from '../../../helpers/api/PaginationRequest'
import RequestInputData from '../../../helpers/api/InputRequest'
import {
  getLatestSearchLocalStorage,
  removeLatestSearchLocalStorage,
  setLatestSearchLocalStorage,
} from '../../../helpers/localStorage/LocalStorage'

export const VolumeSearchPage = () => {
  const path = window.location.href;
  const [isOpen, setIsOpen] = useState(false)
  const [status, setStatus] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [dropdownInfo, setDropdownInfo] = useState({})
  const [request, setRequest] = useState({})
  const [hasSearched, setHasSearched] = useState(false)
  const [latestSearch, setLatestSearch] = useState({
    latestSearchData: null,
    searchParams: [],
    numberOfSearchResult: 0,
    currentTablePage: 1,
    returnPath: '',
  })
  const [storedVolumeResult, setStoredVolumeResult] = useState([])

  useEffect(() => {
    var latestSearch = getLatestSearchLocalStorage(path)
    if (latestSearch) {
      setLatestSearch(latestSearch)
      setHasSearched(true);
      setRequest(latestSearch.searchParams);
    }
    if(latestSearch?.numberOfSearchResult === 0 || !latestSearch){
      setIsOpen(true)
    }
    RequestInputData(apiInputPath, setIsLoading, setDropdownInfo, setStatus)
  }, [])
  const apiSearchPath = '/api/search/DetailedVolumeStorageUnitSearch'
  const apiInputPath = '/api/customer/CustomerSerieData'
  const typeOfStorage = 'volumeSearchStorage'

  useEffect(() => {
    if (storedVolumeResult.length !== 0) {
    localStorage.setItem("volumeSearchStorage", JSON.stringify(storedVolumeResult));
    }
  }, [storedVolumeResult])

  const clearSearchResults = () => {
    setLatestSearch({...latestSearch, latestSearchData: null, searchParams: [], numberOfSearchResult: 0, currentTablePage: 1, returnPath: ''})
    setHasSearched(false)
    removeLatestSearchLocalStorage(path)
    localStorage.removeItem('volumeSearchStorage');
  }

  const requestPagination = (selectedPageNumber) => {
    detailedPaginationRequest(path, setIsLoading, apiSearchPath, typeOfStorage, selectedPageNumber, setLatestSearch, latestSearch, request, setStoredVolumeResult)
  }

  useEffect(() => {
    if(latestSearch?.latestSearchData !== null){
      setLatestSearchLocalStorage(latestSearch)
    }
  }, [latestSearch])

  const buttonTitle = 'Sök Volymer och Förvaringsenheter'

  return (
    <div className="volumeSearchPage">
      <HeaderButton
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonText={buttonTitle}
      />
      {!isLoading ? (
        <div
          className={
            isOpen
              ? 'volumeSearchPage__Form--Open'
              : 'volumeSearchPage__Form--Close'
          }
        >
          <VolumeSearchForm
           latestSearch={latestSearch}
           setLatestSearch={setLatestSearch}
           dropdownInfo={dropdownInfo}
           setIsOpen={setIsOpen}
           setRequest={setRequest}
           setIsLoading={setIsLoading}
           isLoading={isLoading}
           setHasSearched={setHasSearched}
           clearSearchResults={clearSearchResults}
           setStoredVolumeResult={setStoredVolumeResult}
          />
        </div>
      ) : (
        <LoadingSpinner />
      )}
      {!isLoading && hasSearched && (
        <SearchStatus
        isLoading={isLoading}
        latestSearch={latestSearch}
        hasSearched={hasSearched}
        />
      )}
      {!isLoading && latestSearch.latestSearchData != null && (
        <div>
          <DetailedResultTable
            latestSearch={latestSearch}
          />
          <SearchTablePagination
            requestHandlerPagination={requestPagination}
            numberOfResults={latestSearch.numberOfSearchResult}
            tablePage={latestSearch.currentTablePage}

          />
        </div>
      )}
    </div>
  )
}
