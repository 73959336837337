import { React } from 'react'
import PostMessageSender from '../../../../helpers/sender/PostMessageSender'
import Icon from '../../icon/Icon'
import './TableRow.css'

const TableRow = ({result, latestSearch}) => {

  const postMessageToFM = (event) =>{
    PostMessageSender(event, result, latestSearch);
  }

  const TypeNameToSwedish = (englishType) => {
    var swedishType = 'Type'
    switch (englishType) {
      case 'Archive':
        swedishType = 'Arkiv'
        break
      case 'ArchiveHolder':
        swedishType = 'Arkivbildare'
        break
      case 'Depot':
        swedishType = 'Depå'
        break
      case 'Institution':
        swedishType = 'Institution'
        break
      case 'Handlingsslag':
        swedishType = 'Handlingsslag'
        break
      case 'Serie':
        swedishType = 'Serie'
        break
      case 'StorageUnit':
        swedishType = 'Förvaringsenhet'
        break
      case 'Volume':
        swedishType = 'Volym'
        break
      default:
        swedishType = englishType
    }
    return swedishType
  }

  return (
    <tr
      href={'/' + result.Type + '/Edit/' + result.Id} 
      onClick={(event) =>{
        postMessageToFM(event)
        
      }}
      className={`${'table__row'} `}>
      <td className={`${'table__data'} ${'leftAlign__table__data'} ${'table__symbol'}`} data-title="Symbol"> <Icon name="chevron-right-blue"/> </td>
      <td className={`${'table__data'} ${'leftAlign__table__data'}`} data-title="Typ"> {TypeNameToSwedish(result.Type)}</td>
      <td className={`${'table__data'} ${'leftAlign__table__data'}`} title={result.Name} data-title="Namn"> {result.Name}</td>
      <td className={`${'table__data'} ${'leftAlign__table__data'}`} title={result.DepotName}data-title="Depå"> {result.DepotName}</td>
      <td className={`${'table__data'} ${'leftAlign__table__data'}`} title={result.ArchiveholderName}data-title="Arkivbildare"> {result.ArchiveholderName}</td>
      <td className={`${'table__data'} ${'leftAlign__table__data'}`} title={result.ArchiveName}data-title="Arkiv"> {result.ArchiveName}</td>
      <td className={`${'table__data'} ${'leftAlign__table__data'}`} title={result.SeriesOrHandlingsslag}data-title="SerieOrHandlingsslag"> {result.SeriesOrHandlingsslag}</td>
      <td className={`${'table__data'} ${'leftAlign__table__data'}`} title={result.VolumeOrStorageUnit}data-title="VolumeOrStorageUnit"> {result.VolumeOrStorageUnit}</td>
    </tr>
  )
}

export default TableRow
