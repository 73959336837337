import React, { useEffect } from 'react'
import './LoadingSpinner.css'
const LoadingSpinner = () => {
  
  return (
   <>
    <div className='loadingOverlay'></div>
    <div className="loaderIcon lds-ellipsis"><div></div><div></div><div></div><div></div></div>
   </>
  )
}

export default LoadingSpinner