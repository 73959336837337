import React from 'react'
import './HeaderSign.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown }  from '@fortawesome/free-solid-svg-icons'
import { faAngleUp }  from '@fortawesome/free-solid-svg-icons'


const HeaderButtonSign = ({signUnicode, isOpen}) => {

    const arrowDown = <FontAwesomeIcon icon={faAngleDown} />
    const arrowUp = <FontAwesomeIcon icon={faAngleUp} />

    const upOrDown =() => {
        if (isOpen) {
            return arrowDown
        }
        else return arrowUp
        
        
    }

  return (
    upOrDown()
  )
}

export default HeaderButtonSign