
const PostMessageSender = (event, result, latestSearch) =>{

    let messageFromIframe = {}
    messageFromIframe.dataToUpdateMenu = JSON.stringify(result);
    messageFromIframe.href = event.currentTarget.getAttribute('href');
    messageFromIframe.returnPath = latestSearch.returnPath;
    
    //Highlightning does not support object type in Site-FN.
    if(Array.isArray(latestSearch.searchParams)){
        messageFromIframe.searchParams = latestSearch.searchParams.toString();
    }

    else{
        messageFromIframe.searchParams = "Dont´t run highlightning";
    }

    window.parent.postMessage(
        { messageFromIframe },
        process.env.REACT_APP_VA_WEBB + '/Account/Login'
    )

}

export default PostMessageSender