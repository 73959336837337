import React from 'react'
import './DetailedFormSearchButton.css'
import BaseButton from '../baseButton/BaseButton'

const SearchButton = ({SearchButtonOnClick, activeOrDisabled}) => {

 const buttonText = "Sök"
 
  return (
    <BaseButton buttonText={buttonText} buttonClassName={'searchButton' } buttonOnClickFunction={SearchButtonOnClick} activeOrDisabled={activeOrDisabled} />
  )
}

export default SearchButton