import Fetcher from '../fetcher/Fetcher'


const pageSorter = (data) => {
  var objectWithArray = []
  for (let i = 0; i < data.SearchResults.length; i += 20) {
    let pageObject = {
      page: i / 20 + 1,
      data: data.SearchResults.slice(i, i + 20)
    }
    objectWithArray.push(pageObject)
  }
  return objectWithArray
}

export const detailedSearchRequest = async (path, apiPath, setLatestSearch, setIsOpen, setRequest, setIsLoading, setHasSearched, setStoredResult, setUserInput, userInput, setStatus, latestSearch) => {
  setHasSearched(true)
  setIsLoading(true)
  setUserInput({ ...userInput, Page: 1 })
  const requestBody = userInput
  const selectURL = process.env.REACT_APP_API_URL + apiPath
  const responsedata = await Fetcher('POST', requestBody, selectURL)
  setStatus(responsedata.status)
  const data = await responsedata.json()
  if (data) {
    var objectWithArray = pageSorter(data)
    setStoredResult(objectWithArray)
    let firstPagination = data.SearchResults.slice(0, 20)
    setLatestSearch({ ...latestSearch, latestSearchData: firstPagination, numberOfSearchResult: data.TotalCount, currentTablePage: 1, returnPath: path, searchParams: userInput })
    setRequest(userInput)
  }
  setTimeout(function () {
    setIsLoading(false)
  }, 0)
  setIsOpen(false)
}

export const freeSearchRequest = async (searchParameters, setIsLoading, abortController, setHasSearched, setStatus, setStoredFreeResult, setLatestSearch, latestSearch, path) => {
  if (searchParameters.length > 0 && searchParameters[0] !== '') {
    setIsLoading(true)
    setHasSearched(true)
    const requestBody = { searchStrings: searchParameters, page: 1 }
    try {
      abortController.current = new AbortController()
      const responsedata = await Fetcher(
        'POST',
        requestBody,
        process.env.REACT_APP_API_URL + '/api/search/SearchAll',
        abortController.current.signal
      )
      setStatus(responsedata.status)
      const data = await responsedata.json()
      if (data) {
        var objectWithArray = pageSorter(data)
        setStoredFreeResult(objectWithArray)
        let firstPagination = data.SearchResults.slice(0, 20)
        setLatestSearch({ ...latestSearch, latestSearchData: firstPagination, numberOfSearchResult: data.TotalCount, currentTablePage: 1, returnPath: path })
      }
      setTimeout(function () {
        setIsLoading(false)
      }, 0)
    } catch (error) {
      return
    }
  }
}



