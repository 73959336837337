import React, { useState } from 'react';
import Icon from '../../icon/Icon';
import BaseDropDownMenu from '../BaseDropDownMenu/BaseDropDownMenu';
import './advancedSearchButton.css';

const AdvancedSearchButton = ({clickHandler, active}) => {
    const arrowIcon =  <Icon name="caret-down" /> 
    const title = "Detaljerad sökning"
    const optionsArray = [
        {name:"Arkiv", href:"/detailed/archiveholder"},
        {name:"Historik", href:"/detailed/history"},
        {name:"Volymer och Förvaringsenheter", href:"/detailed/volumes"},
    ]
 
    const [options] = useState(optionsArray);
    const [isDropDownActive, setDropDownStatus] = useState(false);
    
    const toggleDropDown= () => {      
        setDropDownStatus(!isDropDownActive); 
    }

    return (

    <div className="dropdown">
        <button
        className={active ? 'advancedSearchButton__selected' : 'advancedSearchButton'}
        onClick={toggleDropDown}>{title} <span className="advancedSearchButton__arrowIcon">{arrowIcon}</span>
        </button>
        
        <BaseDropDownMenu
            optionsArray={options}
            isDropDownActive={isDropDownActive}
            toggleDropDown={toggleDropDown}
            clickHandler={clickHandler}/>
    </div>    
    );
}

export default AdvancedSearchButton