import { React, useEffect, useState } from 'react'
import AdvancedSearchInput from '../../atoms/inputs/advancedSearchInput/AdvancedSearchInput'
import H3Title from '../../atoms/titles/h3Title/H3Title'
import './SearchHistory.css'
import ClearButton from '../../atoms/buttons/clearButton/ClearButton'
import * as utils from '../../../helpers/utils'

const SearchHistory = ({ userInput, setUserInput, latestUserInput, clearSearchResults, setDisableSearch }) => {

  const [searchTerm1, setSearchTerm1] = useState('')
  const [searchTerm2, setSearchTerm2] = useState('')
  const [searchTerm3, setSearchTerm3] = useState('')

  const sokTerm1 = 'Sökterm 1'  
  const sokTerm2 = 'Sökterm 2'
  const sokTerm3 = 'Sökterm 3'

  const infoText = 'Här kan du ange olika söktermer på historik för att få träff på arkiv och arkivbildare.'
  const title = 'Historiktext'

  const clearInputFields = () => {
    clearSearchResults()
    setSearchTerm1("");
    setSearchTerm2("");
    setSearchTerm3("");
  }

  useEffect(()=>{
    setDisableSearch((utils.isEmptyUserInput(userInput) ? true : false ))
  }, [userInput, setDisableSearch])
  
  useEffect(() => {
    setSearchTerm1(latestUserInput.searchStrings ? latestUserInput?.searchStrings[0] : '')
    setSearchTerm2(latestUserInput.searchStrings ? latestUserInput?.searchStrings[1] : '')
    setSearchTerm3(latestUserInput.searchStrings ? latestUserInput?.searchStrings[2] : '')
  }, [latestUserInput])
  

  useEffect(() => {  
    setUserInput({...userInput, searchStrings: [searchTerm1, searchTerm2 , searchTerm3]})
  }, [searchTerm1, searchTerm2 , searchTerm3])

  return (
    <div className="inputFields">
      <div className='infoText'>
      <H3Title h3TitleText={infoText} />
      </div>
      <H3Title h3TitleText={title} />
          
          <div className="multiSearchContainer">
          <AdvancedSearchInput placeholder={sokTerm1} userInput={searchTerm1} setUserInput={setSearchTerm1}/>
            <label className="andSeparator">och</label>
          <AdvancedSearchInput placeholder={sokTerm2} userInput={searchTerm2} setUserInput={setSearchTerm2}/>
            <label className="andSeparator">och</label>
          <AdvancedSearchInput placeholder={sokTerm3} userInput={searchTerm3} setUserInput={setSearchTerm3}/>
          </div>
          <div className='historyClearButton'>
          <ClearButton clearMethod={clearInputFields} />
          </div>
    </div>
  )
}

export default SearchHistory