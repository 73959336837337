import React, { useState, useEffect } from 'react'
import MultiWordInput from '../../molecules/multiWordInput/MultiWordInput'
import ToggleInformationModalButton from '../../atoms/buttons/toggleInformationModalButton/ToggleInformationModalButton'
import InformationModal from '../../molecules/InformationModal/InformationModal'
import SearchWordCountLabel from '../../atoms/labels/searchWordCountLabel/SearchWordCountLabel'
import FreeTextFormSearchButton from '../../atoms/buttons/freeTextFormSearchButton/FreeTextFormSearchButton'
import './FreeSearchForm.css'
import ClearButton from '../../atoms/buttons/clearButton/ClearButton'
import {
  getLatestSearchLocalStorage,
  removeLatestSearchLocalStorage,
  setLatestSearchLocalStorage,
} from '../../../helpers/localStorage/LocalStorage'

const FreeSearchForm = ({
  requestHandler,
  cancelSearch,
  hasSearched,
  latestSearch,
  setLatestSearch
}) => {
  const path = window.location.href;;
  const [toggleInformationModal, setToggleInformationModal] = useState(false)
  const [isSearchWordsArrayEmpty, setIsSearchWordsArrayEmpty] = useState(true)
  
  const [searchParam, setSearchparam] = useState('')
  const [enterPressed, setEnterPressed] = useState(false)
  const [disableSearch, setDisableSearch] = useState(true)


  if (latestSearch.searchParams?.length > 0) {
    hasSearched = true
  }

  useEffect(() => {
    setIsSearchWordsArrayEmpty(!latestSearch.searchParams?.length > 0)
  }, [latestSearch])

  const informationModalToggler = () => {
    setToggleInformationModal(!toggleInformationModal)
  }
  const handleRequest = () => { 
    setEnterPressed(true)
    requestHandler(latestSearch.searchParams)
  }
  const clearFreeSearch = () => {
    cancelSearch()
    setLatestSearch({latestSearchData: null, searchParams: [], numberOfSearchResult: 0, currentTablePage: 1, returnPath: ''})
    removeLatestSearchLocalStorage(path);
    localStorage.removeItem('freeSearchStorage');
  }

  let maxNumberOfSearchParams = 3

  return (
    <div className="searchForm">
      <div className="searchForm__upperContainer">
        <div className="searchForm__Input">
          <MultiWordInput
            handleRequest={handleRequest}
            latestSearch={latestSearch}
            setLatestSearch={setLatestSearch}
            setDisableSearch={setDisableSearch}
            searchParam={searchParam} setSearchparam={setSearchparam}
            enterPressed={enterPressed} setEnterPressed={setEnterPressed}
          />
        </div>

        <div className="searchForm__SearchButton">
          <FreeTextFormSearchButton SearchButtonOnClick={handleRequest} activeOrDisabled={disableSearch} />
        </div>

        <div className="searchForm__InformationButton">
          <ToggleInformationModalButton
            toggleInformationModalButtonOnClickFunction={
              informationModalToggler
            }
          />
        </div>
        {toggleInformationModal ? (
          <InformationModal informationModalToggler={informationModalToggler} />
        ) : null}
      </div>
      <div className="searchForm__buttomContainer">
        <div className={'searchForm__wordCount'}>
          {latestSearch.searchParams?.length > 0 ? (
            <SearchWordCountLabel
              currentValue={latestSearch.searchParams?.length}
              maxValue={maxNumberOfSearchParams}
            />
          ) : null}
        </div>
        {latestSearch.searchParams.length > 0 && (
          <ClearButton clearMethod={clearFreeSearch} />
        )}
      </div>
    </div>
  )
}

export default FreeSearchForm
