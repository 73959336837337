import { React, useState, useEffect } from 'react'
import './HistorySearchForm.css'
import SearchHistory from '../../molecules/searchHistory/SearchHistory'
import DetailedFormSearchButton from '../../atoms/buttons/detailedFormSearchButton/DetailedFormSearchButton'
import {setLatestSearchLocalStorage} from '../../../helpers/localStorage/LocalStorage'
import {detailedSearchRequest} from '../../../helpers/api/SearchRequest'

const HistorySearchForm = ({ latestSearch, setLatestSearch, setIsOpen, setRequest, setIsLoading, setHasSearched, clearSearchResults, setStoredHistoryResult}) => {
  const path = window.location.href;
  const [status, setStatus] = useState()
  const [userInput, setUserInput] = useState({
      searchStrings: [], Page: 1})
  const [disableSearch, setDisableSearch] = useState(true)

      const apiSearchPath = '/api/search/DetailedHistorySearch'

      const handleRequest = () => {
        detailedSearchRequest(path, apiSearchPath, setLatestSearch, setIsOpen, setRequest, setIsLoading, setHasSearched, setStoredHistoryResult, setUserInput, userInput, setStatus, latestSearch)
    }

  useEffect(() => {
    if(latestSearch?.latestSearchData !== null){
      setLatestSearchLocalStorage(latestSearch)
    }
  }, [latestSearch])

  return (
    <div className="historySearchForm">
      <SearchHistory
        userInput={userInput} 
        setUserInput={setUserInput}
        latestUserInput={latestSearch.searchParams}
        clearSearchResults={clearSearchResults} 
        setDisableSearch={setDisableSearch}/>
      <div className="historySearchForm__buttonContainer">
      <DetailedFormSearchButton SearchButtonOnClick={handleRequest} activeOrDisabled={disableSearch}/>
      </div>
    </div>
  )
}

export default HistorySearchForm