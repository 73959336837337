import { useEffect } from 'react'
import { React, useRef } from 'react'
import './SearchInput.css'

const SearchInput = ({ searchInputHandler, searchInputValue, focusIn, focusOut, focusToInputRef, latestSearch, maxNumberOfSearchParams}) => {
  const searchInputPlaceholder = 'Vänligen ange ett eller flera sökord'
  const noSearchInputPlaceholder = ''

  return (
    <div className='multiWordInputRow'>
      <input
        className="multiWordInputRow__SearchInput"
        onChange={searchInputHandler}
        onFocus={focusIn}
        onBlur={focusOut}
        value={searchInputValue}
        placeholder={latestSearch.searchParams.length < 1 ? searchInputPlaceholder : noSearchInputPlaceholder}
        ref={focusToInputRef}

        disabled={maxNumberOfSearchParams}
      ></input>
    </div>
  )
}

export default SearchInput
