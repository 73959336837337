import React, {useState, useEffect} from 'react'
import ErrorModalMessage from '../../atoms/messages/errorModalMessage/ErrorModalMessage'
import './ErrorMessageModal.css'

const ErrorMessageModal = ({ statusCode, clearStatusCode }) => {
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('Ej definerat')
  const [descriptionText, setDescriptionText] = useState('')

useEffect(() => {
  errorToggler()
}, [statusCode])


  const errorToggler = () => {
      if (statusCode === 401) {
        setShowError(true)
        setErrorMessage(statusCode)
      }
      else if (statusCode === 400) {
        setShowError(true)
        setErrorMessage(statusCode)
      }
      else if (statusCode !== 200 && statusCode != 401) {
        setShowError(false)
        let genericErrortext =
          'Ett fel verkar ha uppstått, vänligen försök igen senare.'
        setErrorMessage(genericErrortext)
      }
      else{
        setShowError(false)
      }
  

    if (statusCode === 200 || statusCode === 401) {
      setDescriptionText('Testa att starta om sökfuntionen.')
    } 
    else {
      setDescriptionText('Felet som uppstod är av den ovanligare typen. Vänligen testa igen senare.')
    }    
  }
    const onButtonClick = () => {
      setShowError(false)
      clearStatusCode()
      setDescriptionText('')
    }
  return (
    <div className={'errorMessageModal'}>
    {showError ? <ErrorModalMessage errorMessage={errorMessage} descriptionText={descriptionText} onButtonClick={onButtonClick} /> : null}
    </div>
    
  )
}

export default ErrorMessageModal