import React from 'react'
import Icon from '../../icon/Icon'
import BaseButton from '../baseButton/BaseButton'
import './AddSearchPhraseButton.css'

const AddSearchPhraseButton = ({addSearchPhraseButtonOnClickFunction, latestSearch}) => {
   
  const addSearchPhraseButtonText = <Icon name="plus" className='add-search-phrase-icon'/>

  return (
    <BaseButton buttonText={addSearchPhraseButtonText} buttonOnClickFunction={addSearchPhraseButtonOnClickFunction} buttonClassName={latestSearch.searchParams.length < 3 ? 'activeAddSearchPhraseButton' : 'inactiveAddSearchPhraseButton'} />
  )
}

export default AddSearchPhraseButton