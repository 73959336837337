import './HeaderButtonText.css'


const HeaderButtonText = ({isOpen}) => {

    const arrowPointingDown = 'Dölj sökfält'
    const arrowPointingUp = 'Ändra sökning'

    const arrowUpOrDown =() => {
        if (isOpen) {
            return arrowPointingDown
        }
        else return arrowPointingUp
    }

  return (
    arrowUpOrDown()
  )
}

export default HeaderButtonText
