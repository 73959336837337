import {React, useState} from 'react'
import PaginationButtons from '../../molecules/pagination/paginationButtons/PaginationButtons'
import './SearchTablePagination.css'

const SearchTablePagination = ({ numberOfResults, requestHandlerPagination, resetLatestSearchData, tablePage}) => {
    
    const [selectedValue, setSelectedValue] = useState(tablePage ? tablePage : 1)
    const numberOfRowsPerPage = 20
    const totalNumberOfPages = Math.ceil(numberOfResults / numberOfRowsPerPage)
            
            const clickLink = (text) => {
              if (text == 'Första') {
                setSelectedValue(1)
                text = 1
              } else if (text == 'Sista') {
                setSelectedValue(totalNumberOfPages)
                text = totalNumberOfPages
              } else {
                setSelectedValue(text)
              }
              if(resetLatestSearchData){
                resetLatestSearchData()
              }
              requestHandlerPagination(text)
            }
            
            if ( !numberOfResults ) {
              return
            }
            
            return (
              <div className={'paginationHolderStyle'}> 
    <PaginationButtons 
      selectedValue={selectedValue} 
      totalNumberOfPages={totalNumberOfPages}
      tablePage={tablePage} 
      clickLink={clickLink} />
  </div>
  )
}

export default SearchTablePagination