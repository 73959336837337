import React from 'react'
import SearchStatusMessage from '../../atoms/messages/searchStatusMessage/SearchStatusMessage'
import './SearchStatus.css'

const SearchStatus = ({
  hasSearched,
  parentPage,
  latestSearch
}) => {
  let statusMessage = ''

  let selectedTableRangeHigh = latestSearch.currentTablePage * 20
  let selectedTableRangeLow = latestSearch.currentTablePage * 20 + 1 - 20
  let showTableRange = `${selectedTableRangeLow} - ${selectedTableRangeHigh}`

  if(parentPage === "freeSearch")
  {
 
    if (selectedTableRangeHigh >= latestSearch.numberOfSearchResult) {
      var converValue = selectedTableRangeHigh - latestSearch.numberOfSearchResult - 20
      selectedTableRangeHigh = selectedTableRangeHigh - converValue - 20
      showTableRange = `${selectedTableRangeLow} - ${selectedTableRangeHigh}`
    }
    
    if (latestSearch?.numberOfSearchResult > 0)
      statusMessage = `Din sökning på "${latestSearch.searchParams}" resulterade i ${latestSearch?.numberOfSearchResult} träffar. Visar resultat ${showTableRange}`
    if (latestSearch.numberOfSearchResult === 0)
      statusMessage = `Söktermen "${latestSearch.searchParams}" resulterade i ${latestSearch.numberOfSearchResult} träffar. Vänligen sök på ett nytt ord`
    if (latestSearch.searchParams.length === 0) statusMessage = 'Vänligen välj ett ord att söka på'
  }
  else
  {
    if (selectedTableRangeHigh >= latestSearch.numberOfSearchResult) {
      var converValue = selectedTableRangeHigh - latestSearch.numberOfSearchResult - 20
      selectedTableRangeHigh = selectedTableRangeHigh - converValue - 20
      showTableRange = `${selectedTableRangeLow} - ${selectedTableRangeHigh}`
    }
    
    if (latestSearch?.numberOfSearchResult > 0)
    statusMessage = `Din sökning resulterade i ${latestSearch?.numberOfSearchResult} träffar. Visar resultat ${showTableRange}`
    if (latestSearch?.numberOfSearchResult === 0)
    statusMessage = `Söktermen resulterade i ${latestSearch?.numberOfSearchResult} träffar. Vänligen sök på ett nytt ord`
    if (hasSearched === false) statusMessage = ``
    if (latestSearch.searchParams.length === 0) statusMessage = 'Vänligen välj ett ord att söka på'
  }
  return (
    <div className='searchStatus'>
      {hasSearched && (
        <SearchStatusMessage searchStatusMessageText={statusMessage} />
      )}
    </div>
  )
}


export default SearchStatus
