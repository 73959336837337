import { React, useEffect } from 'react'
import TableHead from '../../atoms/table/tableHead/TableHead'
import TableRow from '../../atoms/table/tableRow/TableRow'
import './DetailedResultTable.css'

const DetailedResultTable = ({ latestSearch}) => {

  return (
    <table className={`${'table'}`}>
      <TableHead/>
      <tbody>
          {latestSearch.latestSearchData?.map((result, index) => (
            <TableRow
              latestSearch={latestSearch}
              key={index}
              result = {result}
            />
          ))}
      </tbody>
    </table>
  )
}

export default DetailedResultTable
