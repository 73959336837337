import { React, useEffect, useState } from 'react'
import * as NAD from '../../../data/NADCategories.js'
import { detailedPaginationRequest } from '../../../helpers/api/PaginationRequest'
import Fetcher from '../../../helpers/fetcher/Fetcher'
import {
  getLatestSearchLocalStorage,
  removeLatestSearchLocalStorage,
  setLatestSearchLocalStorage,
} from '../../../helpers/localStorage/LocalStorage'
import LoadingSpinner from '../../atoms/spinners/loadingSpinner/LoadingSpinner'
import HeaderButton from '../../molecules/headerButton/HeaderButton'
import SearchStatus from '../../molecules/searchStatus/SearchStatus'
import ArchiveSearchForm from '../../organisms/archiveSearchForm/ArchiveSearchForm'
import DetailedResultTable from '../../organisms/detailedResultTable/DetailedResultTable'
import SearchTablePagination from '../../organisms/searchTablePagination/SearchTablePagination'
import './ArchiveSearchPage.css'


export const ArchiveSearchPage = () => {
  const returnPath = window.location.href;
  const [isOpen, setIsOpen] = useState(false)
  const [status, setStatus] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [dropdownInfo, setDropdownInfo] = useState({})
  const [request, setRequest] = useState({})
  const [hasSearched, setHasSearched] = useState(false)

  const [latestSearch, setLatestSearch] = useState({
    latestSearchData: null,
    searchParams: [],
    numberOfSearchResult: 0,
    currentTablePage: 1,
    returnPath: '',
  })

  useEffect(() => {
    var latestSearch = getLatestSearchLocalStorage(returnPath)
    if (latestSearch) {
      setLatestSearch(latestSearch)
      setHasSearched(true);
      setRequest(latestSearch.searchParams);
    }
    if(latestSearch?.numberOfSearchResult === 0 || !latestSearch){
      setIsOpen(true)
    }
    RequestInputData()
  }, [])
  const [storedArchiveResult, setStoredArchiveResult] = useState([])

  const path = '/detailed/archiveholder'
  const apiSearchPath = '/api/search/DetailedArchiveSearch'
  const typeOfStorage = 'archiveSearchStorage'
  
  useEffect(() => {
    if (storedArchiveResult.length !== 0) {
      localStorage.setItem("archiveSearchStorage", JSON.stringify(storedArchiveResult));
    }
  }, [storedArchiveResult])

  const RequestInputData = async () => {
    setIsLoading(true)
    const requestBody = {}
    const selectURL =
      process.env.REACT_APP_API_URL + '/api/customer/CustomerData'
    const responsedata = await Fetcher('POST', requestBody, selectURL)
    setStatus(responsedata.status)
    const data = await responsedata.json()
    if (data) {
      setTimeout(function () {
        setIsLoading(false)
      }, 0)
      
      populateDropdowns(data)
      setDropdownInfo(data)
    }
  }

  const populateDropdowns = (data) => { 
        data.nadCategories = Object.freeze(NAD.nadCategories)
  }


  const requestPagination = (selectedPageNumber) => {
    detailedPaginationRequest(path, setIsLoading, apiSearchPath, typeOfStorage, selectedPageNumber, setLatestSearch, latestSearch, request, setStoredArchiveResult)
  }

  useEffect(() => {
    if(latestSearch?.latestSearchData !== null){
      setLatestSearchLocalStorage(latestSearch)
    }
  }, [latestSearch])

  const clearSearchResults = () => {
    setLatestSearch({...latestSearch, latestSearchData: null, searchParams: [], numberOfSearchResult: 0, currentTablePage: 1, returnPath: ''})
    setHasSearched(false)
    removeLatestSearchLocalStorage(returnPath);
    localStorage.removeItem('archiveSearchStorage');
  }
  const buttonTitle = 'Sök Arkiv'

  return (
    <div className="archiveSearchPage">
      
      <HeaderButton
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonText={buttonTitle}
      />
      {!isLoading ? (
        <div
          className={
            isOpen
              ? 'archiveSearchPage__Form--Open'
              : 'archiveSearchPage__Form--Close'
          }
        >
          <ArchiveSearchForm
            latestSearch={latestSearch}
            setLatestSearch={setLatestSearch}
            dropdownInfo={dropdownInfo}
            setIsOpen={setIsOpen}
            setRequest={setRequest}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setHasSearched={setHasSearched}
            clearSearchResults={clearSearchResults}
            setStoredArchiveResult={setStoredArchiveResult}
          />
        </div>
      ) : <LoadingSpinner />}
        {!isLoading && hasSearched && ( 
        <SearchStatus
          isLoading={isLoading}
          latestSearch={latestSearch}
          hasSearched={hasSearched}/>)}
      {!isLoading && latestSearch.latestSearchData != null && (
        <div>
          <DetailedResultTable 
            latestSearch={latestSearch}
           />
          <SearchTablePagination
            requestHandlerPagination={requestPagination}
            numberOfResults={latestSearch.numberOfSearchResult}
            tablePage={latestSearch.currentTablePage}/>
        </div>
      )}
    </div>
  )
}
