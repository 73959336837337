import React from 'react'
import BaseButton from '../baseButton/BaseButton'
import './InformationModalButton.css'

const InformationModalButton = ({InformationModalButtonText, InformationModalButtonOnclick} ) => {
  return (
    <BaseButton buttonText={InformationModalButtonText} buttonOnClickFunction={InformationModalButtonOnclick} buttonClassName={'informationModalButtonStyle'}/>
  )
}

export default InformationModalButton