import React from 'react'

const SearchStatusMessage = ({searchStatusMessageText}) => {
  return (
    <h4>
        <br/>
        {searchStatusMessageText}
    </h4>
  )
}

export default SearchStatusMessage