export function checkProperties(obj) {
    for (var key in obj) {
        if (obj[key] !== null && obj[key] != "")
            return false;
    }
    return true;
}

export function isEmptyUserInput(obj) {
    for (var key in obj) {
        if(obj[key]?.length > 1)
        {
            for(var x in obj[key])
            {
                var element = obj[key][x]
                if (element !== "" && element !== undefined){
                    return false;
                }
            }
        }
        else if (obj[key] !== null && obj[key] != "" && obj[key] !== undefined && key !== "Page")
        {   
            return false;  
        } 
    }
    return true;
}