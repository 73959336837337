import { React, useState, useEffect } from 'react'
import HeaderButton from '../../molecules/headerButton/HeaderButton'
import DetailedResultTable from '../../organisms/detailedResultTable/DetailedResultTable'
import HistorySearchForm from '../../organisms/historySearchForm/HistorySearchForm'
import SearchTablePagination from '../../organisms/searchTablePagination/SearchTablePagination'
import SearchStatus from '../../molecules/searchStatus/SearchStatus'
import './HistorySearchPage.css'
import LoadingSpinner from '../../atoms/spinners/loadingSpinner/LoadingSpinner'
import {detailedPaginationRequest} from '../../../helpers/api/PaginationRequest'
import {
  getLatestSearchLocalStorage,
  removeLatestSearchLocalStorage,
  setLatestSearchLocalStorage,
} from '../../../helpers/localStorage/LocalStorage'

export const HistorySearchPage = () => {
  const path = window.location.href;
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [request, setRequest] = useState({})
  const [hasSearched, setHasSearched] = useState(false)
  const [storedHistoryResult, setStoredHistoryResult] = useState([])

  const apiSearchPath = '/api/search/DetailedHistorySearch'
  const typeOfStorage = 'historySearchStorage'

  const [latestSearch, setLatestSearch] = useState({
    latestSearchData: null,
    searchParams: [],
    numberOfSearchResult: 0,
    currentTablePage: 1,
    returnPath: '',
  })

  useEffect(() => {
    if (storedHistoryResult.length !== 0) {
    localStorage.setItem("historySearchStorage", JSON.stringify(storedHistoryResult));
    }
  }, [storedHistoryResult])

  useEffect(() => {
    var latestSearch = getLatestSearchLocalStorage(path)
    if (latestSearch) {
      setLatestSearch(latestSearch)
      setHasSearched(true);
      setRequest(latestSearch.searchParams);
    }
    if(latestSearch?.numberOfSearchResult === 0 || !latestSearch){
      setIsOpen(true)
    }
  }, [])

  const clearSearchResults = () => {
    setLatestSearch({...latestSearch, latestSearchData: null, searchParams: [], numberOfSearchResult: 0, currentTablePage: 1, returnPath: ''})
    setHasSearched(false)
    localStorage.removeItem('historySearchStorage');
    removeLatestSearchLocalStorage(path);
  }

  const requestPagination = (selectedPageNumber) => {
    detailedPaginationRequest(path, setIsLoading, apiSearchPath, typeOfStorage, selectedPageNumber, setLatestSearch, latestSearch, request, setStoredHistoryResult)
  }

  useEffect(() => {
    if(latestSearch?.latestSearchData !== null){
      setLatestSearchLocalStorage(latestSearch)
    }
  }, [latestSearch])

  const buttonTitle = 'Sök Historik'

  return (
    <div className="historySearchPage">
      <HeaderButton
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonText={buttonTitle}
      />
      {!isLoading ? (
        <div
          className={
            isOpen
              ? 'historySearchPage__Form--Open'
              : 'historySearchPage__Form--Close'
          }
        >
          <HistorySearchForm
            latestSearch={latestSearch}
            setLatestSearch={setLatestSearch}
            setIsOpen={setIsOpen}
            setRequest={setRequest}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setHasSearched={setHasSearched}
            clearSearchResults={clearSearchResults}
            setStoredHistoryResult={setStoredHistoryResult}
          />
        </div>
      ) : (
        <LoadingSpinner />
      )}
      {!isLoading && hasSearched && (
        <SearchStatus
          isLoading={isLoading}
          latestSearch={latestSearch}
          hasSearched={hasSearched}
        />
      )}
      {!isLoading && latestSearch.latestSearchData != null && (
        <div>
          <DetailedResultTable
            latestSearch={latestSearch}
          />
          <SearchTablePagination
            requestHandlerPagination={requestPagination}
            numberOfResults={latestSearch.numberOfSearchResult}
            tablePage={latestSearch.currentTablePage}
          />
        </div>
      )}
    </div>
  )
}