import React from 'react'
import Icon from '../../icon/Icon'
import BaseButton from '../baseButton/BaseButton'
import './SearchPhraseButton.css'

const SearchPhraseButton = ({searchWord,removeSearchWord}) => {

  const SearchPraseButtonClick = () => {
    removeSearchWord(searchWord)
    
  }

  const xMark = <Icon name="close" className='search-phrase-button-icon'/>

  return (
    <div className={'searchPhraseButton'}>
    <p className={'searchPhraseText'}>{searchWord}</p>
    <BaseButton  buttonText={xMark} buttonOnClickFunction={SearchPraseButtonClick} buttonClassName={'searchPhraseRemove'}/>
    </div>
  )
}

export default SearchPhraseButton