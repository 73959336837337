import { React } from 'react'
import './TableHead.css'

const TableHead = ({ result }) => {
  return (
    <thead>
      <tr className={`${'table__row__header'}`}>
        <th
          className={`${'table__header'} ${'table__header__xxsmall'} ${'table__header__type'}`}
        ></th>
        <th
          className={`${'table__header'} ${'table__header__small'} ${'table__header__type'}`}
          scope="col"
        >
          TYP
        </th>
        <th
          className={`${'table__header'} ${'table__header__small'} ${'table__header__type'}`}
          scope="col"
        >
          NAMN
        </th>
        <th
          className={`${'table__header'} ${'table__header__small'} ${'table__header__type'}`}
          scope="col"
        >
          DEPÅ
        </th>
        <th
          className={`${'table__header'} ${'table__header__small'} ${'table__header__type'}`}
          scope="col"
        >
          ARKIVBILDARE
        </th>
        <th
          className={`${'table__header'} ${'table__header__small'} ${'table__header__type'}`}
          scope="col"
        >
          ARKIV
        </th>
        <th
          className={`${'table__header'} ${'table__header__small'} ${'table__header__type'}`}
          scope="col"
        >
          SERIE / HANDLINGSSLAG
        </th>
        <th
          className={`${'table__header'} ${'table__header__small'} ${'table__header__type'}`}
          scope="col"
        >
          VOLYM / FÖRVARINGSENHET
        </th>
      </tr>
    </thead>
  )
}
export default TableHead
