import React from 'react';
import { useNavigate } from 'react-router-dom';
import BaseButton from '../baseButton/BaseButton';
import './freeTextSearchButton.css';

const FreeTextSearchButton = ({setActive, active, clickHandler}) => {
    
    let navigate = useNavigate();
    const title = "Fritextsökning"
    
    const onClick = () => {
        navigate('/')
        clickHandler("free")
    }

    return (
        <BaseButton buttonText={title} buttonClassName={active ? 'freeTextSearchButton__selected' : 'freeTextSearchButton'} buttonOnClickFunction={onClick}/>
      )
}

export default FreeTextSearchButton