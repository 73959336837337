import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BaseDropDownMenu.css';


const BaseDropDownMenu = ({optionsArray, isDropDownActive, toggleDropDown, clickHandler}) =>{
    const node = useRef();
    let location = useLocation();
    
    useEffect(() => {
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside);
        }
    }, [isDropDownActive])
    
    const clickDropdownHandler = () => {
        toggleDropDown()
        clickHandler("hej")
    }
    
    var optionItems = optionsArray.map(({name, href, index}) =>{
    
       return(
        <Link key={name} to={href} >{name}  </Link>
       )
    })

    const clickOutside = (e) => {
        if(!node.current.contains(e.target)) {
            if(isDropDownActive){
                toggleDropDown()   
            }             
        } 
    }

    return(             
        <div 
        ref={node}
        onClick={() => clickDropdownHandler()}
        className={isDropDownActive ? "dropdown__content": "dropdown__content__inactive"}>
            {optionItems}
        </div>
    )
}

export default BaseDropDownMenu