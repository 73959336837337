import { React, useEffect, useState } from 'react'
import BaseDropdownInput from '../../atoms/inputs/baseDropdownInput/BaseDropdownInput'
import AdvancedSearchInput from '../../atoms/inputs/advancedSearchInput/AdvancedSearchInput'
import H3Title from '../../atoms/titles/h3Title/H3Title'
import './SearchVolumes.css'
import ClearButton from '../../atoms/buttons/clearButton/ClearButton'
import * as utils from '../../../helpers/utils'

const SearchVolumes = ({ dropdownInfo, userInput, setUserInput, latestUserInput, clearSearchResults, setDisableSearch}) => {
  const [archiveHolderName, setArchiveHolderName] = useState(latestUserInput.archiveHolderName ? latestUserInput.archiveHolderName : '')
  const [serieSignumOrHandlingsslagStructure, setSerieSignumOrHandlingsslagStructure] = useState(latestUserInput.serieSignumOrHandlingsslagStructure ? latestUserInput.serieSignumOrHandlingsslagStructure : '')
  const [seriesNameOrHandlingsslagName, setSeriesNameOrHandlingsslagName] = useState(latestUserInput.seriesNameOrHandlingsslagName ? latestUserInput.seriesNameOrHandlingsslagName : '')
  const [note, setNote] = useState(latestUserInput.note ? latestUserInput.note : '')
  const [handlingsTyp, setHandlingsTyp] = useState(latestUserInput.handlingsTyp ? latestUserInput.handlingsTyp : '')
  const [volumeNumberOrStorageUnitBeteckning, setVolumeNumberOrStorageUnitBeteckning] = useState(latestUserInput.volumeNumberOrStorageUnitBeteckning ? latestUserInput.volumeNumberOrStorageUnitBeteckning : '')
  const [scope, setScope] = useState(latestUserInput.scope ? latestUserInput.scope : '')
  const [storageUnitType, setStorageUnitType] = useState(latestUserInput.storageUnitType ? latestUserInput.storageUnitType : '')
  const [placement, setPlacement] = useState(latestUserInput.placement ? latestUserInput.placement : '')

  const [noteSearchTerm1, setNoteSearchTerm1] = useState(latestUserInput.noteSearchParameters ? latestUserInput?.noteSearchParameters[0] : '')
  const [noteSearchTerm2, setNoteSearchTerm2] = useState(latestUserInput.noteSearchParameters ? latestUserInput?.noteSearchParameters[1] : '')
  const [noteSearchTerm3, setNoteSearchTerm3] = useState(latestUserInput.noteSearchParameters ? latestUserInput?.noteSearchParameters[2] : '')

  const arcHolName = 'Arkiv-/Arkivbildarnamn'
  const sigBe = 'Signum/Beteckning'  
  const rubNam = 'Rubrik/Namn'
  const anm = 'Anmärkning'  
  const handTyp = 'Handlingstyp'
  const volBet = 'Volymnummer/Beteckning'  
  const tid = 'Tid'
  

  const forEnTyp = 'Typ av förvaringsenhet'  
  const plac = 'Placering'
  
  const anmSokTerm1 = 'Anmärkning sökterm 1'  
  const anmSokTerm2 = 'Anmärkning sökterm 2'
  const anmSokTerm3 = 'Anmärkning sökterm 3'

  const title1 = 'Arkiv/Arkivbildare'
  const title2 = 'Serie/Handlingsslag'
  const title3 = 'Handlingstyp'
  const title4 = 'Volym/Förvaringsenhet'

  const clearInputFields = () => {
    clearSearchResults()
    setArchiveHolderName("");
    setSerieSignumOrHandlingsslagStructure("");
    setSeriesNameOrHandlingsslagName("");
    setNote("");
    setHandlingsTyp("");
    setVolumeNumberOrStorageUnitBeteckning("");
    setScope("");  
    setStorageUnitType("");
    setPlacement("");  
    setNoteSearchTerm1("");
    setNoteSearchTerm2("");
    setNoteSearchTerm3("");
  }
  
  useEffect(()=>{
    setDisableSearch((utils.isEmptyUserInput(userInput) ? true : false ))
  }, [userInput, setDisableSearch])

  useEffect(() => {  
    setUserInput({...userInput, archiveHolderName: archiveHolderName, serieSignumOrHandlingsslagStructure: serieSignumOrHandlingsslagStructure, seriesNameOrHandlingsslagName: seriesNameOrHandlingsslagName, note: note, 
    handlingsTyp: handlingsTyp, volumeNumberOrStorageUnitBeteckning:volumeNumberOrStorageUnitBeteckning, scope: scope,
    storageUnitType: storageUnitType, placement : placement, noteSearchParameters: [noteSearchTerm1, noteSearchTerm2 , noteSearchTerm3]})
  }, [archiveHolderName, serieSignumOrHandlingsslagStructure, seriesNameOrHandlingsslagName, note, handlingsTyp, volumeNumberOrStorageUnitBeteckning, 
    scope, storageUnitType,placement,noteSearchTerm1, noteSearchTerm2 , noteSearchTerm3])
  
  return (
    <div className="inputFields">
      <H3Title h3TitleText={title1} />
          <AdvancedSearchInput placeholder={arcHolName} userInput={archiveHolderName} setUserInput={setArchiveHolderName} />
      <H3Title h3TitleText={title2} />
          <AdvancedSearchInput placeholder={sigBe} userInput={serieSignumOrHandlingsslagStructure} setUserInput={setSerieSignumOrHandlingsslagStructure} />
          <AdvancedSearchInput placeholder={rubNam} userInput={seriesNameOrHandlingsslagName} setUserInput={setSeriesNameOrHandlingsslagName} />
          <AdvancedSearchInput placeholder={anm} userInput={note} setUserInput={setNote} />
      <H3Title h3TitleText={title3} />
          {/* make it array */}
          <BaseDropdownInput dropdownInfo={dropdownInfo.handlingstyper} placeholder={handTyp} userInput={handlingsTyp} setUserInput={setHandlingsTyp}/>
      <H3Title h3TitleText={title4} />
          <AdvancedSearchInput placeholder={volBet} userInput={volumeNumberOrStorageUnitBeteckning} setUserInput={setVolumeNumberOrStorageUnitBeteckning} />
          <AdvancedSearchInput placeholder={tid} userInput={scope} setUserInput={setScope} />

          <BaseDropdownInput dropdownInfo={dropdownInfo.storageUnitTypes} placeholder={forEnTyp} userInput={storageUnitType} setUserInput={setStorageUnitType}/>
          <AdvancedSearchInput placeholder={plac} userInput={placement} setUserInput={setPlacement}/>
          <br/>
          <div className="multiSearchContainer">
          <AdvancedSearchInput placeholder={anmSokTerm1} userInput={noteSearchTerm1} setUserInput={setNoteSearchTerm1}/>
            <label className="andSeparator">och</label>
          <AdvancedSearchInput placeholder={anmSokTerm2} userInput={noteSearchTerm2} setUserInput={setNoteSearchTerm2}/>
            <label className="andSeparator">och</label>
          <AdvancedSearchInput placeholder={anmSokTerm3} userInput={noteSearchTerm3} setUserInput={setNoteSearchTerm3}/>
          </div>
          <div className='serieVolumeClearButton'>
          <ClearButton clearMethod={clearInputFields} />
          </div>
      
    </div>
  )
}

export default SearchVolumes
